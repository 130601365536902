<template>
	<div class="container_bottom">
		<div class="w1200">
			<div class="wapper">
				<ul class='flex_a'>
					<li v-for="(item,index) in list" :key="index" :class="{'checked' : tabIndex == index}" @click="changeTab(index)">{{item.name}}</li>
				</ul>
<!--				<p class="phone">-->
<!--					联系电话：0516-88888888<span class="spanBorder">地址：江苏省徐州市泉山区解放南路2号</span>-->
<!--				</p>-->
				<p class="phone">
					地址：江苏省徐州市泉山区解放南路2号
				</p>
				<p class="address">
					淮海战役烈士纪念塔管理中心（淮海战役纪念馆）<span class="spanBorder">苏ICP备2022027373号-1</span><span class="flex_a spanBorder"><a
						target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32031102001454"  class="flex_a"><img src="@/assets/images/jh.png"/>苏公网安备 32031102001454号</a></span>
				</p>

				<div class="bomImg">
					<img src="@/assets/images/logo_dibu.png"/>
				</div>

			</div>
		</div>



	</div>
</template>

<script>
	export default {
		name: 'bottom',
		data(){
			return{
				list: [
					{name: '景区导览'},
					{name: '信息公开'},
					{name: '馆藏精品'},
					{name: '网上祭扫'},
					// {name: '预约服务'},
					{name: '学术研究'},
				],
				tabIndex: '',

			}
		},
		watch: {
			$route: {
				handler(val) {
					console.log(val.name);
					if(val.name == 'guide'){
						this.tabIndex = 0
					}
					if(val.name == 'information'){
						this.tabIndex = 1
					}
					if(val.name == 'boutique'){
						this.tabIndex = 2
					}
					if(val.name == 'sweep'){
						this.tabIndex = 3
					}
					// if(val.name == 'reservation'){
					// 	this.tabIndex = 4
					// }
					if(val.name == 'yanjiu'){
						this.tabIndex = 4
					}

				}
			}
		},
		computed:{

		},
		methods: {
			changeTab(index) {
				this.tabIndex = index
				if(this.tabIndex == 0){
					this.$router.push({ path: "/guide"});
				}
				if(this.tabIndex == 1){
					sessionStorage.setItem('infoIndex',1)
					this.$store.commit('SET_INFO',1)
					this.$router.push('/information')
				}
				if(this.tabIndex == 2){
					sessionStorage.setItem('gcIndex',0)
					this.$store.commit('SET_GC',0)
					this.$router.push('/boutique')
				}
				if(this.tabIndex == 3){
					this.$router.push('/sweep')
				}
				// if(this.tabIndex == 4){
				// 	this.$router.push('/reservation')
				// }
				if(this.tabIndex == 4){
					sessionStorage.setItem('yjIndex',1)
					this.$store.commit('SET_YJ',1)
					this.$router.push('/yanjiu')
				}
			}
		}

	}
</script>

<style lang="scss">
	.container_bottom{
		background: #5B2528;
		.w1200{
			background: url('../assets/images/bottom_bg.png') no-repeat;
			background-size: cover;
			height: 209px;
			display: flex;
			align-items: center;
		}
		.wapper{
			width: 780px;
			margin: 0 auto;
			color: #DCDCDC;
			li{
				font-size: 18px;
				margin-right: 61px;
				position: relative;
				cursor: pointer;
				color: #F1F1F1;
				&:last-of-type{
					margin: 0;
					&::after{
						display: none;
					}
				}
				&::after{
					content: '';
					width: 5px;
					height: 5px;
					background: #B2A877;
					position: absolute;
					right: -31px;
					top: 50%;
					margin-top: -2.5px;
					border-radius: 50%;
				}
			}
			.checked{
				color: #B2A877;
			}
			.spanBorder{
				&::after{
					position: absolute;
					content: '';
					width: 1px;
					height: 14px;
					background: #B2A877;
					left: 0;
					top: 50%;
					margin-top: -7px;
				}
			}
			.phone{
				margin-top: 35px;
				margin-bottom: 15px;
				span{
					display: inline-block;
					position: relative;
					padding-left: 15px;
					margin-left: 15px;

				}
			}
			.address{
				display: flex;
				span{
					position: relative;
					padding-left: 15px;
					margin-left: 15px;
					img{
						margin-right: 5px;
					}
				}
			}
			.bomImg{
				text-align: center;
				margin-top: 15px;
				img{
					margin: 0 auto;
				}
			}

		}

	}
</style>
